(function( $ ) {
    $( document ).ready(function() {

        if( $('.js-campaign-modal-form').length ) {
            $('.js-campaign-modal-form').magnificPopup({
                items: {
                    src: "#campaign-form-modal",
                },
            });            
        }

        var formModalBtns = document.querySelectorAll('.js-campaign-modal-form');
        for (var i = 0; i < formModalBtns.length; i++) {
            formModalBtns[i].addEventListener("click",function(){
                if (this.dataset.title){
                    document.querySelector("#campaign-form-modal .c-form-modal__title").innerText = this.dataset.title;
                }

                if (document.querySelector("#campaign-form-modal .c-form__title")){
                    document.querySelector("#campaign-form-modal .c-form__title").remove();
                }

                var steps = document.querySelectorAll('#campaign-form-modal .c-form__step');
                var stepStart = 1;
                if (this.dataset.stepstart)stepStart = this.dataset.stepstart;
                for (var j = 0; j < steps.length; j++) {
                    var stepStatus = steps[j].querySelectorAll('.c-form__status-step');
                    for (var k = 0; k < stepStatus.length; k++) {
                        if (stepStatus[k].innerText.toLowerCase().indexOf("step") == 0){
                            var span = document.createElement("span");
                            span.textContent = 'Step '+(parseInt(stepStart) + k);
                            stepStatus[k].replaceChildren(span);
                        }
                    }
                }
            });
        }



        if( $('.js-imageLightbox').length ) {
            $('.js-imageLightbox').magnificPopup({
                type: 'image',
                mainClass:'c-lightbox c-lightbox--image',
                verticalFit: false,
                fixedBgPos: true,
                fixedContentPos: true
            });
        }

        if( $('.js-gallery').length ) {    
            $('.js-gallery').each(function() { // the containers for all your galleries
                $(this).magnificPopup({
                    delegate: 'a', // the selector for gallery item
                    type: 'image',
                    gallery: {
                    enabled:true
                    }
                });
            });
        }


        if( $('.js-modalLightbox').length ) {
            $('.js-modalLightbox').magnificPopup({
                type:'inline',
                closeBtnInside:true,
                mainClass:'c-lightbox c-lightbox--modal',
                verticalFit: false,
                fixedBgPos: true,
                fixedContentPos: true
            });
        }



        // Video Lightbox
        if( $('.js-videoLightbox').length ) {

            // Load wistia in an iframe on video click, and if the element has a CTA, show/hide during video playing.
            $('.js-videoLightbox').magnificPopup({
                type: 'inline',
                mainClass:'c-lightbox c-lightbox--video',
                callbacks: {
                    elementParse: function(item) {
                        item.src = '<div class="mfp-iframe-scaler"><button title="Close (Esc)" type="button" class="mfp-close">×</button><iframe src="https://fast.wistia.net/embed/iframe/'+item.el.data('vid')+'?autoPlay=true&silentAutoPlay=allow" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allow="autoplay" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="640" height="360"></iframe></div><script src="//fast.wistia.com/assets/external/E-v1.js" async></script>';
                    },
                    open: function() {
                        var $cta = $('#video-cta--' + $(this.currItem.el).data('vid'));
                        if ( $cta.length ) {
                            $cta.removeClass('is-active');
                        }
                    },
                    close: function() {
                        var $cta = $('#video-cta--' + $(this.currItem.el).data('vid'));
                        if ( $cta.length ) {
                            $cta.addClass('is-active');
                        }
                    }
                }
            });

            // If there are CTAs, make sure close button works.
            if ( $('.js-closeVideoCTA').length ) {
                $('.js-closeVideoCTA').on('click', function(e){
                    var $cta = $('#video-cta--' + $(this).data('vid'));
                    //console.log('#video-cta--' + $(this).data('vid'));
                    $cta.removeClass('is-active');
                })
            }

        } // End Video Lightbox Code



    });
})(jQuery);
