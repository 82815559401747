var calculatorForm;

(function($) {

    $(document).ready(function() {

        today = new Date();
        $('[data-currentmonth]').data('currentmonth', today.getMonth() + 1);
        $('[data-currentyear]').data('currentyear', today.getFullYear());

        preyears = $('[data-currentmonth] option').filter(function(i, e) {
            return $(this).val() != '' && $(this).val() < $('[data-currentmonth]').data('currentmonth');
        });

        // select stuff
        $('[data-currentyear]').on('change', function(e) {
            if ($(this).val() == $(this).data('currentyear')) {
                if ($('[data-currentmonth]').val() < $('[data-currentmonth]').data('currentmonth') && $('[data-currentmonth]').val() != '') {
                    $('[data-currentmonth]').val('');
                    var formGroup = $('[data-currentmonth]').closest('.js-formGroup');
                    if (formGroup[0].className.includes('has-error') == false) {
                        formGroup[0].className += ' has-error';
                    }
                }
                preyears.hide();
                preyears.attr('disabled', 'disabled');
            } else {
                preyears.show();
                preyears.removeAttr('disabled');
            }
        });

        function toFixed(value, precision) {
            var precision = precision || 0,
                power = Math.pow(10, precision),
                absValue = Math.abs(Math.round(value * power)),
                result = (value < 0 ? '-' : '') + String(Math.floor(absValue / power));

            if (precision > 0) {
                var fraction = String(absValue % power),
                    padding = new Array(Math.max(precision - fraction.length, 0) + 1).join('0');
                result += '.' + padding + fraction;
            }
            return result;
        }

    })

    window.calculatorForm = function() {
        tdate = new Date();
        tmonth = tdate.getMonth();
        tyear = tdate.getFullYear();

        month = $('[name="payment_month"]').val();
        year = $('[name="payment_year"]').val();
        sdate = new Date(month + "/01/" + year);
        w = $('[name="payment_amount"]').val(); //Payment Amount #USER INPUT
        w = parseFloat(w.replace(/[^\d\.\-\ ]/g, ''));
        n = $('[name="number_of_payments"]').val(); //Number of Payments #USER INPUT
        n = parseFloat(n.replace(/[^\d\.\-\ ]/g, ''));
        payperiod = parseFloat($('[name="payment_period"]').val()); //How many months between payments (Monthly, Semi-Annually, etc) #USER SELECT
        wait = sdate.getMonth() - tmonth + (12 * (sdate.getFullYear() - tyear)); //Months before payment starts
        months = n * payperiod + wait;
        minmonths = 60; //Minimum amount of months for a quote.
        minval = 25000; //Minimum amount of total value.
        maxpaymentnums = 500; //Maximum amount of number of payments.
        maxval = 1000000; //Maximum amount of total value.
        totpayments = n * w;
        if (months < minmonths || totpayments < minval || totpayments > maxval || n > maxpaymentnums || wait < 0) {
            n = 0;
        } else {
            r = .09; //Discount Rate
            comp = 12; //Amount of times interest is compounded
            rmonth = r / comp; //Discount Rate per month
        }
        balance = 0;
        if (n > 0) {
            for (i = 0; i < n; i++) {
                for (j = 1; j < payperiod; j++) {
                    interest = (balance * rmonth) / (1 + rmonth);
                    balance = balance - interest;
                }
                interest = (balance * rmonth) / (1 + rmonth);
                balance = balance + w - interest;
            }
            balance = balance / (Math.pow(1 + rmonth, wait));
            fixedBal = isNaN(balance.toFixed(2)) ? "0" : balance.toFixed(2);
            $('.js-calculation').html("$" + fixedBal);
            $('.js-calculatorForm').append('<input type="hidden" name="quote_amount" value="' + fixedBal + '">');
        } else {
            $('.js-calculation').html("Contact Us");
        }
    }
})(jQuery);