jQuery.noConflict();
(function($) {
    $(function() {
        var lastScroll = 0;

        $(window).scroll(function() {
            var scroll = $(document).scrollTop();
            if( scroll > 80 ) {
                $('body').addClass('has-scrolled');

                if (scroll > lastScroll) {
                    //downscroll
                    $('body').addClass('hide-site-header');
                } else {
                    //upscroll
                    $('body').removeClass('hide-site-header');
                }

            } else {
                $('body').removeClass('has-scrolled');
            }
            lastScroll = scroll <= 0 ? 0 : scroll;
        });

        $('.js-lightbox-img').magnificPopup({
            type: 'image'
            // other options
        });
    });
})(jQuery);
